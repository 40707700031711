<template>
    <div class="container">
        <div v-if="!isMobile" class="pcWrapper">
            <div class="ach__relationsWrapper">
                <div class="ach_bannerWrapper">
                    <img src="https://img.i2soft.net/i2official-web/assets/images/relations/ser_back.png"/>
                    <!-- <div class="bannerTextWrapper">
                      <span class="bannerText1">行业格局已经发生巨变</span>
                      <span class="bannerText2">我们为您提供场景化的</span>
                      <span class="bannerText3">深度行业解决方案</span>
                    </div> -->

                    <div class="bannerTextWrapper">
                        <span class="bannerText1">投资者服务</span>
                        <!-- <span class="bannerText3">深度行业解决方案</span> -->
                    </div>
                </div>
                <div class="announcementWrapper">
                    <div class="top_box">
                        <div class="pc_change">
                            <div>
                                <a @click.prevent="jump('anc')" v-bind:class="{'active':(staic == 'anc')}">公司公告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('gov')" v-bind:class="{'active':(staic == 'gov')}">公司治理</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a @click.prevent="jump('acv')" v-bind:class="{'active':(staic == 'acv')}">业绩报告</a>
                                <a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <a v-bind:class="{'active':(staic == 'ser')}">投资者服务</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mapWrapper">
                    <div class="service_box">
                        <div class="wrapper">
                            <div class="items">
                                <div class="item">
                                    <div class="img-box">
                                        <img src="https://img.i2soft.net/i2official-web/assets/images/relations/Adress_back.png" alt="">
                                    </div>
                                    <span></span>
                                    <div class="txt-box">
                                        <h3 class="h3">地址</h3>
                                        <p>上海市浦东新区陆家嘴街道陆家嘴环路958号华能联合大厦4楼</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="img-box">
                                        <img src="https://img.i2soft.net/i2official-web/assets/images/relations/Phone_back.png" alt="">
                                    </div>
                                    <span></span>
                                    <div class="txt-box">
                                        <h3 class="h3">投资者热线</h3>
                                        <p>021-68816719</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="img-box">
                                        <img src="https://img.i2soft.net/i2official-web/assets/images/relations/Email_back.png" alt="">
                                    </div>
                                    <span></span>
                                    <div class="txt-box">
                                        <h3 class="h3">投资者邮箱</h3>
                                        <p>public@i2finance.net</p>
                                    </div>
                                </div>
                                <div class="item">
                                    <a href="https://ir.p5w.net/c/830799.shtml" target="_blank" style="color: #333333">
                                        <div class="img-box">
                                            <img src="https://img.i2soft.net/i2official-web/assets/images/relations/Help_back.png" alt="">
                                        </div>
                                        <span></span>
                                        <div class="txt-box">
                                            <h3 class="h3">投资者互动平台</h3>
                                            <p>立即跳转</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {isMobile} from "../../utils/index";

    export default {
        data() {
            return {
                isMobile: false,
                // 年报
                annualList: [],
                // 中报
                InterimList: [],
                // 季报
                QuarterlyList: [],
                datas: [],
                page: {
                    totle: '',
                    currentPage: 2,
                    pageSize: 8
                },
                staic: '',
                query_type: '',
            };
        },
        filters: {},
        created() {
            this.getachievent();
            this.query_type = this.$route.query.category;
            this.pg_change(this.query_type);
            window.scrollTo(0,540);
        },
        mounted() {
            this.isMobile = isMobile();
            window.addEventListener("scroll", this.getScroll);
        },
        destroyed() {
            window.removeEventListener("scroll", this.getScroll);
        },
        methods: {
            // 跳转
            jump(type){
                if(type == "anc")  {
                    this.$router.push({ path: "/Announce" , query: { category: type } });
                } else if(type == "gov"){
                    this.$router.push({ path: "/government",query: { category: type }  });
                }else if(type == "acv"){
                    this.$router.push({ path: "/Achievement",query: { category: type }  });
                }
            },
            // 跳转后tab标签的改变
            pg_change(type){
                console.log("static",type);
                console.log("this.query_type",this.query_type);
                this.staic = type;
            },
            // 获取业绩表
            getachievent(){
                // 年报业报
                this.$axios.get("/parameter/annualList").then((res) => {
                    this.annualList = res.data.data;
                    console.log("年报业报",this.annualList)
                })
                // 中报业报
                this.$axios.get("/parameter/InterimList").then((res) => {
                    this.InterimList = res.data.data;
                    console.log("中报业报",this.InterimList)
                })
                // 季报业报
                this.$axios.get("/parameter/QuarterlyList").then((res) => {
                    this.QuarterlyList = res.data.data;
                    console.log("季报业报",this.QuarterlyList)
                })
            },
            getScroll() {
                // this.indexNumTop = $("#index_num").offset().top;
                this.scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (!!document.documentElement.scrollTop && document.documentElement.scrollTop >= 70
                ) {
                    //设置滚动大于300时的执行内容
                    // console.log(
                    //   "document.documentElement.scrollTop=",
                    //   document.documentElement.scrollTop
                    // );
                    this.$store.commit("TO_AfterScroll");
                } else {
                    this.$store.commit("TO_AfterScroll_false");
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    * {
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .ach__relationsWrapper {
        background-color: #ffffff;

        .ach_bannerWrapper {
            width: 100%;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            .bannerTextWrapper {
                position: absolute;
                top: 45%;
                left: 45%;
                display: flex;
                flex-direction: column;

                .bannerText1 {
                    font-size: 30px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText2 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 40px;
                    text-align: left;
                    font-family: PingFangSC-Medium;
                }

                .bannerText3 {
                    color: rgba(255, 255, 255, 100);
                    font-size: 30px;
                    text-align: left;
                    font-family: PingFangSC-Light;
                }
            }
        }

        .announcementWrapper {
            .top_box {
                background-color: rgba(246, 246, 246, 1);
                position: relative;
                padding: 1px 0px;
                height: 60px;
                top: -3px;
            }

            .pc_change {
                position: absolute;
                left: 60%;
                z-index: 1;
            }

            .pc_change div {
                margin-right: 10px;
                position: relative;
                margin-top: 6%;
                cursor: default;
                .active{
                     padding-bottom: 5px;
                     border-bottom: 2px solid #265ea2;
                     color: #265ea2;
                 }
            }

            .pc_change a:nth-child(2n-1) {
                text-decoration: none;
                color: #000000;
                font-size: 15px;
                cursor: pointer;
            }

            .pc_change a:nth-child(2) {
                font-size: 15px;
            }

            .pc_change a:nth-child(2n-1):hover {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }

            .pc_change a:nth-child(2n-1):visited {
                padding-bottom: 5px;
                border-bottom: 2px solid #265ea2;
                color: #265ea2;
            }
        }

        .mapWrapper {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding: 0px;
            .service_box{
                padding: 6.25vw 0;
                .wrapper {
                    width: 90%;
                    max-width: 1560px;
                    margin: 0 auto;
                    position: relative;
                    z-index: 2;
                }
                .items{
                    margin: 0;
                    padding: 0;
                    text-align: left;
                }
                .item {
                    display: inline-block;
                    vertical-align: top;
                    width: calc((100% - 3.2vw) / 2);
                    position: relative;
                    background-color: #fff;
                    transition: all .3s ease;
                }
                .item .img-box {
                    position: relative;
                    z-index: 2;
                    overflow: hidden;
                }
                .item img {
                    display: block;
                    width: 100%;
                    vertical-align: middle;
                    border: none;
                }
                .item:nth-of-type(1) span {
                    background-image: url(https://img.i2soft.net/i2official-web/assets/images/relations/adder.png);
                    width: 36px;
                    height: 36px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    left: 2.5vw;
                    top: 2vw;
                    z-index: 3;
                    transition: all .3s ease;
                }
                .item:nth-of-type(2) span {
                    background-image: url(https://img.i2soft.net/i2official-web/assets/images/relations/phone.png);
                    width: 36px;
                    height: 36px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    left: 2.5vw;
                    top: 2vw;
                    z-index: 3;
                    transition: all .3s ease;
                }
                .item:nth-of-type(3) span {
                    background-image: url(https://img.i2soft.net/i2official-web/assets/images/relations/email.png);
                    width: 36px;
                    height: 36px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    left: 2.5vw;
                    top: 2vw;
                    z-index: 3;
                    transition: all .3s ease;
                }
                .item:nth-of-type(4) span {
                    background-image: url(https://img.i2soft.net/i2official-web/assets/images/relations/help.png);
                    width: 36px;
                    height: 36px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    left: 2.5vw;
                    top: 2vw;
                    z-index: 3;
                    transition: all .3s ease;
                }
                .item .txt-box {
                    position: absolute;
                    left: 2.5vw;
                    right: 2.5vw;
                    bottom: 2.5vw;
                    transition: all .3s ease;
                    z-index: 3;
                }
                .service_box .item h3 {
                    font-size: 18px;
                    color: #333333;
                    transition: color .3s ease;
                    font-weight: normal;
                }
                .service_box .item p {
                    font-size: 14px;
                    margin-top: 10px;
                    color: #808080;
                    transition: color .3s ease;
                }
            }

        }
    }

</style>


